import * as React from "react";
import { graphql } from "gatsby";

import Breadcrumps from "@components/Breadcrumbs";
import Seo from "@components/Seo";

import ContactLabel from "@page_components/contact/ContactLabel";
import ContactForm from "@page_components/contact/ContactForm";
import Map from "@page_components/contact/Map";

function ContactPageEN({ data }) {
  const wp_data = data.allWpPage.nodes[0].pageContact;
  const { seo } = data.seo_data.nodes[0];
  const {
    contactPhone,
    contactAddress,
    contactAgreement,
    contactHours,
    contactMail,
  } = wp_data;

  const breadcrumbs_data = [{ name: "Contact" }];

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="contact-page">
        <Breadcrumps data={breadcrumbs_data} />
        <h1> Contact </h1>
        <div className="container">
          <aside className="contact-page__info">
            <div className="contact-page__contact-labels">
              {contactPhone.map((item) => (
                <ContactLabel type="phone" key={item.phonenumber}>
                  {item.phonenumber}
                </ContactLabel>
              ))}
              <ContactLabel type="email">{contactMail}</ContactLabel>
            </div>
            <div className="contact-page__hours">
              <p>Opening hours:</p>
              <div dangerouslySetInnerHTML={{ __html: contactHours }} />
            </div>

            <div className="contact-page__address">
              <p>Address:</p>
              <div dangerouslySetInnerHTML={{ __html: contactAddress }} />
            </div>

            <div className="contact-page__map">
              <Map />
            </div>
          </aside>
          <main className="contact-page__form">
            <h3>Write to us</h3>
            <ContactForm agreement_label={contactAgreement} />
          </main>
        </div>
      </div>
    </>
  );
}

export default ContactPageEN;

export const query = graphql`
  {
    allWpPage(filter: { title: { eq: "Contact" } }) {
      nodes {
        pageContact {
          contactAddress
          contactAgreement
          contactHours
          contactMail
          contactPhone {
            phonenumber
          }
        }
      }
    }
    seo_data: allWpPage(filter: { title: { eq: "Contact" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }
  }
`;
